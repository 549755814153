<template>
  <div :style="{ paddingTop: `${MENU_BAR_HEIGHT}px` }">
    <v-container>
      <v-row align="center">
        <v-col class="pt-8 pt-md-16 pb-8" cols="12" lg="8" xxl="7">
          <h1
            v-if="value.title"
            class="text-h3 text-sm-h2 font-weight-regular mb-3"
            style="line-height: 118%"
          >
            <StyledText
              em-class="text-light-blue-darken-2 font-weight-medium"
              :value="value.title"
            />
          </h1>

          <template v-if="Array.isArray(value.paragraphs)">
            <p
              v-for="(p, i) in value.paragraphs"
              :key="i"
              class="text-body-0 mb-3 font-weight-regular"
            >
              <StyledText :value="p" />
            </p>
          </template>
          <p
            v-else-if="value.paragraph"
            class="text-body-1 text-sm-h6 font-weight-regular"
          >
            <StyledText :value="value.paragraph" />
          </p>

          <div
            v-if="value.primary_nav_items && value.primary_nav_items.length"
            class="mt-4"
          >
            <v-btn
              v-for="item in value.primary_nav_items"
              :key="item.href"
              :append-icon="mdiArrowRight"
              :href="item.href"
              :target="hrefTarget(item.href)"
              class="text-none mr-3 my-2"
              color="secondary"
              exact
              rounded="pill"
              size="large"
            >
              {{ item.title }}</v-btn
            >
          </div>

          <div
            v-if="value.secondary_nav_items && value.secondary_nav_items.length"
            class="mt-8"
          >
            <p class="text-h5 mb-2">How We Help:</p>

            <v-btn
              v-for="item in value.secondary_nav_items"
              :key="item.href"
              :href="item.href"
              :target="hrefTarget(item.href)"
              class="text-none mr-3 my-2"
              color="cyan-lighten-5"
              exact
              flat
              rounded="pill"
              size="large"
              style="opacity: 0.8"
            >
              {{ item.title }}</v-btn
            >
          </div>

          <v-breadcrumbs
            v-if="breadcrumbs"
            :items="breadcrumbs"
            divider="|"
            class="flex-wrap"
          >
            <template #title="{ item }">
              <v-icon v-if="item.href === '/'" :icon="mdiHome" size="20" />
              <span v-else class="text-h6 text-uppercase font-weight-regular">{{
                item.title
              }}</span>
            </template>
          </v-breadcrumbs>
        </v-col>

        <v-col
          v-if="$slots['cta']"
          :class="home ? 'align-start' : 'align-center'"
          class="d-none d-lg-flex"
          lg="4"
          xl="4"
          offset-xxl="1"
        >
          <slot name="cta" />
        </v-col>

        <v-col
          v-else-if="home"
          class="d-none d-lg-flex"
          style="position: relative"
          lg="4"
          offset-xxl="1"
        >
          <div
            class="bg-cyan-lighten-5 rounded-circle"
            style="
              min-width: 550px;
              min-height: 550px;
              position: absolute;
              bottom: 40px;
              left: -10px;
            "
          />

          <v-img
            v-if="imgResp.src"
            :aspect-ratio="aspectRatio"
            :sizes="imgResp.sizes"
            :src="imgResp.src"
            :srcset="imgResp.srcset"
            alt="Image for the hero section"
            class="mb-n4 ml-6"
            cover
            max-width="501"
            min-width="501"
            width="501"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-sheet v-if="$slots['footer']" class="bg-light-blue-darken-2 py-2">
      <v-container>
        <v-row>
          <v-col cols="12">
            <slot name="footer" />
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script setup>
import { computed, toRef } from 'vue'
import { MENU_BAR_HEIGHT } from '#root/lib/consts'
import { useImgResponsive } from '#root/lib/img'
import { hrefTarget } from '#root/lib/utils'
import { mdiArrowRight, mdiHome } from '@mdi/js'

const props = defineProps({
  home: {
    default: false,
    type: Boolean
  },
  navBreadcrumbs: {
    default: undefined,
    type: Array
  },
  value: {
    required: true,
    type: Object
  }
})

const aspectRatio = 501 / 769
const imgResp = useImgResponsive(
  toRef(() => ({
    aspectRatio,
    image: props.value.side_image
  }))
)
const breadcrumbs = computed(() => {
  return (
    props.navBreadcrumbs && [
      {
        title: 'Home',
        href: '/'
      },
      ...props.navBreadcrumbs
    ]
  )
})
</script>
